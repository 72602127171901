<template>
  <div class="menu-list" :class="{'is-open': isOpen}" @click.stop>
    <!-- <div class="menu-item">首页</div> -->
    <div class="menu-item" v-for="(item, index) in menu" :key="index" @click="onMenuClick(index)">
      {{ item.title }}
      <div class="sub-menu" v-if="item.children.length">
        <div class="sub-menu-item" v-for="(sub, subIndex) in item.children" :key="subIndex"
             @click.stop="onSubMenuClick(index, subIndex)">{{ sub.title }}
        </div>
      </div>
    </div>

    <!--    <div class="menu-item">-->
    <!--      系统设置-->

    <!--      <div class="sub-menu">-->
    <!--        <div @click="showIndexSetting" class="sub-menu-item">首页设置</div>-->
    <!--        <div @click="showWarnSetting"  class="sub-menu-item">预警设置</div>-->
    <!--        &lt;!&ndash; <div class="sub-menu-item">监控设置</div> &ndash;&gt;-->

    <!--        <div v-for="item in menuList" @click="toMenu(item)" class="sub-menu-item">{{item.label}}</div>-->
    <!--      </div>-->
    <!--    </div>-->

    <img v-show="!isOpen" @click.stop="open" class="up-arr flash-animate1" src="@/assets/img/county/up-arr.png" alt=""
         srcset="">
    <img v-show="isOpen" @click.stop="close" class="down-arr" src="@/assets/img/county/down-arr.png" alt="" srcset="">
  </div>
</template>
<script>
import { getMenuList } from '@/api/accountApi'

export default {

  props: {
    moduleList: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      isOpen: false,
      menuList: [],
      settingMapper: {
        homeSetModal: {
          visible: 'homeSetModal'
        },
        warningSetModal: {
          visible: 'warningSetModal'
        }
      }
    }
  },

  computed: {
    menu () {
      const menuList = []
      const moduleList = this.moduleList
      for (const i in moduleList) {
        menuList.push({
          ...moduleList[i],
          ...{ key: i },
          children: []
        })
      }

      menuList.push({
        title: '系统设置',
        children: [
          { title: '首页设置', key: 'homeSetModal' },
          { title: '预警设置', key: 'warningSetModal' },
          // { title: '监控设置', key: 'warningSetModal' }
        ]
      })
      return menuList
    },

    isClient () {
      return this.$store.getters['user/isClient']
    }
  },

  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    toMenu (item) {
      window.open(item.href)
    },

    onMenuClick (index) {
      this.$emit('menu-click', { templateCode: this.menu[index].key })
    },

    onSubMenuClick (index, subIndex) {
      this.$emit('sub-menu-click', { templateCode: this.menu[index].children[subIndex].key })
    }
  },
}
</script>
<style lang="scss" scoped>
.menu-list {
  width: 100%;
  height: 1.16rem;
  line-height: 1.16rem;
  background: rgba(0, 9, 30, 0.85);
  position: absolute;
  z-index: 5;
  bottom: -1.16rem;
  text-align: center;
  transition: 0.5s;
}

.is-open {
  bottom: 0;
}

.menu-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 0.5rem;
  width: 1.3rem;
  height: 0.42rem;
  background: url(../../../assets/img/city/bottom-tab-menu.png);
  text-align: center;
  line-height: 0.42rem;
  color: #fff;
  background-size: cover;
}

.menu-item:hover {
  color: #FFA800;
}

.up-arr {
  cursor: pointer;
  width: 0.52rem;
  height: 0.2rem;
  position: absolute;
  top: -0.2rem;
  left: 50%;
  transform: translateX(-50%);
}

.down-arr {
  cursor: pointer;
  width: 0.52rem;
  height: 0.2rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sub-menu {
  display: none;
  color: #fff;
  transition: 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  border: 1px solid #0185D9;
}

.menu-item:hover .sub-menu {
  display: block;
}

.sub-menu-item {
  cursor: pointer;
  width: 1.28rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  background: #01194A;
}

.sub-menu-item:hover {
  background: #00072F;
}
</style>

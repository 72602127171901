import axios from './axios'
import qs from 'qs'
import {oauthUrl, eduUrl} from '@/config/config'


export const login = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: oauthUrl + '/api/auth/login',
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getUserInfo = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: eduUrl + '/api/account/users/user-detail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}



export const geetest = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: oauthUrl + '/api/auth/login/geetest',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const sendMessage = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: oauthUrl + '/api/auth/login/login-sms',
            method: 'post',
            data: qs.stringify(data)
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getMenuList = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: eduUrl + '/api/account/menu-permissions',

            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const forgetPwd = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: oauthUrl + '/api/auth/login/forget-pwd',
            method: 'put',
            data
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
